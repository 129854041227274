<template>
  <div class="success">
    <img src="../assets/images/success.png">
    <div style="margin: 16px;">
      <van-button  class="save" round block type="info" @click="$router.push('recode')">完成</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Success'
}
</script>

<style scoped lang="less">
  .success{
    img{
      display: block;
      width: 100px;
      margin:  120px auto 60px auto;
    }
    .save{
      background-color: #AA0018;
      border-color: #AA0018;
    }
  }
</style>
